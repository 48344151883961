<template>
  <div class="tables">
    <div class="top">
      <!-- <img class="top-banner" src="../src/assets/bg-header@3x.png" alt="" /> -->
      <img class="top-label" src="../assets/title@3x.png" alt="" />
      <div class="clock">
        <p>{{ weekDay }}</p>
        <p>
          <span class="date">{{ date }}</span>
          <span class="time">{{ time }}</span>
        </p>
      </div>
    </div>
    <div class="main">
      <div class="table-top">
        <div class="back" @click="backHome">
            <span
            ><img class="back-icon" src="../assets/home.png" alt=""
            /></span>
          <span class="back-text">返回首页</span>
        </div>
        <div class="store-title">
          <span class="title">
            {{name=='youzan'?'有赞营业额':name=='todayorders'?'今日订单':name=='merchant'?'合作商户':name=='customerall'?'累计客户':name=='customertoday'?'今日新增客户':name}}
          </span>
        </div>
      </div>
      <router-view></router-view>
<!--      <component is="todayorders"></component>-->
    </div>
    <img class="bg-bt" src="../assets/bg-bottom.png" alt="" />
  </div>
</template>

<script>
/*todayorders今日订单详细状况*/
/**/
export default {
  name: "tables",
  data(){
    return{
      weekDay: "",
      date: "",
      time: "",
      name:''
    }
  },
  created() {
    this.getTime();
    setInterval(this.getTime, 1000);
    this.name = this.$route.query.name
  },
  methods:{
    getTime() {
      let now = new Date();
      let day = now.getDay();
      let weeks = new Array(
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六"
      );
      this.weekDay = weeks[day];
      let date =
          now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
      this.date = date;
      let h = now.getHours();
      let m = now.getMinutes();
      let s = now.getSeconds();
      if (h < 10) h = "0" + h.toString();
      if (m < 10) m = "0" + m.toString();
      if (s < 10) s = "0" + s.toString();
      this.time = h + ":" + m + ":" + s;
    },
    backHome() {
      this.$router.push("/home");
    },
  },
  watch:{
    '$route.query.name'(){
      this.name = this.$route.query.name
    }
  }
}
</script>

<style scoped>
.tables{
  height: 100%;
  background-image: url("../assets/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.top {
  width: 100%;
  height: 11%;
  position: relative;
  background-image: url("../assets/bg-header@3x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-label {
  margin-top: -10px;
  width: 20%;
  z-index: 99;
}
</style>
